import { Favorite } from '@styled-icons/material';
import styled, { css } from 'styled-components';
import { grey, paleGrey, red } from '../../utils/colors';
import { mobile } from '../../utils/media';
import rem from '../../utils/rem';
import { BlmBanner } from '../BlmBanner';
import { Content } from '../Layout';
import Link from '../Link';

export default function Footer() {
  return (
    <>
      <Wrapper>
        <FooterContent $hero>styled-components 中文网 - 粤ICP备13048890号</FooterContent>
      </Wrapper>
      
    </>
  );
}

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${grey};
  background: ${paleGrey};
  box-sizing: border-box;
  margin-top: ${rem(50)};
`;

const Heart = styled(Favorite)`
  display: inline-block;
  width: ${rem(17)};
  color: ${red};
  transform: translateY(-10%);
`;

const FooterLink = styled(Link)`
  color: ${grey};
`;

const FooterContent = styled(Content)`
  padding: ${rem(30)} ${rem(40)} ${rem(30)} ${rem(40)};

  ${mobile(css`
    padding: ${rem(30)} ${rem(20)} ${rem(30)} ${rem(20)};
  `)};
`;

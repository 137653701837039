/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {Content} from 'components/Layout';
import {AlignCenter, Badge, ExampleButton, SecondButton} from './components';
import NextPage from '../../components/NextPage';
const MDXLayout = ({children}) => _jsxs(Content, {
  "data-e2e-id": "content",
  children: [_jsx(AlignCenter, {}), children]
});
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    a: "a"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "入门(Getting started)"
    }), "\n", _jsx(_components.p, {
      children: "¥Getting started"
    }), "\n", _jsx(_components.h2, {
      children: "安装(Installation)"
    }), "\n", _jsx(_components.p, {
      children: "¥Installation"
    }), "\n", _jsx(_components.p, {
      children: "要下载 styled-components，请运行："
    }), "\n", _jsx(_components.p, {
      children: "¥To download styled-components run:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "npm install styled-components\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "这就是你需要做的全部，你现在可以在你的应用中使用它了！（是的，无需构建步骤👌）"
    }), "\n", _jsx(_components.p, {
      children: "¥That's all you need to do, you are now ready to use it in your app! (yep, no build step needed 👌)"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["如果可以的话，建议（但不是必需）也使用 ", _jsx(_components.a, {
          href: "https://github.com/styled-components/babel-plugin-styled-components",
          children: "styled-components Babel 插件"
        }), "。它提供了许多好处，例如更清晰的类名、服务器端渲染兼容性、更小的包等等。"]
      }), "\n", _jsxs(_components.p, {
        children: ["¥It's recommended (but not required) to also use the ", _jsx(_components.a, {
          href: "https://github.com/styled-components/babel-plugin-styled-components",
          children: "styled-components Babel plugin"
        }), " if you can. It offers many benefits like more legible class names, server-side rendering compatibility, smaller bundles, and more."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      children: "你的第一个样式组件(Your first styled component)"
    }), "\n", _jsx(_components.p, {
      children: "¥Your first styled component"
    }), "\n", _jsxs(_components.p, {
      children: ["假设你想要创建一个可以在整个应用中使用的简单且可重用的 ", _jsx(_components.code, {
        children: "<Button />"
      }), " 组件。应该有一个普通版本和一个重要按钮的大版本和 ", _jsx(_components.code, {
        children: "primary"
      }), " 版本。渲染时应该是这样的：（这是一个活生生的例子，点击它们！）"]
    }), "\n", _jsxs(_components.p, {
      children: ["¥Let's say you want to create a simple and reusable ", _jsx(_components.code, {
        children: "<Button />"
      }), " component that you can use throughout your application. There should be a normal version and a big and ", _jsx(_components.code, {
        children: "primary"
      }), " version for the important buttons. This is what it should look like when rendered: (this is a live example, click on them!)"]
    }), "\n", _jsxs(AlignCenter, {
      children: [_jsxs(ExampleButton, {
        onClick: () => {
          alert('You clicked the normal button!');
        },
        children: [_jsx(_components.p, {
          children: "普通按钮"
        }), _jsx(_components.p, {
          children: "¥Normal button"
        })]
      }), _jsxs(ExampleButton, {
        $primary: true,
        onClick: () => {
          alert('You clicked the primary button!');
        },
        children: [_jsx(_components.p, {
          children: "主按钮"
        }), _jsx(_components.p, {
          children: "¥Primary button"
        })]
      })]
    }), "\n", _jsxs(_components.p, {
      children: ["首先，让我们导入 styled-components 并创建一个 ", _jsx(_components.code, {
        children: "styled.button"
      }), "："]
    }), "\n", _jsxs(_components.p, {
      children: ["¥First, let's import styled-components and create a ", _jsx(_components.code, {
        children: "styled.button"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-tsx",
        children: "import styled from 'styled-components'\n\nconst Button = styled.button``\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["这里的 ", _jsx(_components.code, {
        children: "Button"
      }), " 变量现在是一个 React 组件，你可以像任何其他 React 组件一样使用它！这种不寻常的反引号语法是一种新的 JavaScript 功能，称为 ", _jsx(_components.a, {
        href: "https://web.nodejs.cn/en-US/docs/Web/JavaScript/Reference/Template_literals#Tagged_templates",
        children: "标记模板字面量"
      }), "。"]
    }), "\n", _jsxs(_components.p, {
      children: ["¥This ", _jsx(_components.code, {
        children: "Button"
      }), " variable here is now a React component that you can use like any other React component! This unusual backtick syntax is a new JavaScript feature called a ", _jsx(_components.a, {
        href: "https://web.nodejs.cn/en-US/docs/Web/JavaScript/Reference/Template_literals#Tagged_templates",
        children: "tagged template literal"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["你知道如何用括号调用函数吗？(", _jsx(_components.code, {
        children: "myFunc()"
      }), ")好吧，现在你还可以使用反引号调用函数！(", _jsx(_components.a, {
        href: "/docs/advanced#tagged-template-literals",
        children: "了解有关标记模板字面量的更多信息"
      }), ")"]
    }), "\n", _jsxs(_components.p, {
      children: ["¥You know how you can call functions with parenthesis? (", _jsx(_components.code, {
        children: "myFunc()"
      }), ") Well, now you can also call functions with backticks! (", _jsx(_components.a, {
        href: "/docs/advanced#tagged-template-literals",
        children: "learn more about tagged template literals"
      }), ")"]
    }), "\n", _jsxs(_components.p, {
      children: ["如果你现在渲染我们可爱的组件（就像任何其他组件一样：", _jsx(_components.code, {
        children: "<Button />"
      }), "），你将得到以下结果："]
    }), "\n", _jsxs(_components.p, {
      children: ["¥If you render our lovely component now (just like any other component: ", _jsx(_components.code, {
        children: "<Button />"
      }), ") this is what you get:"]
    }), "\n", _jsx(AlignCenter, {
      children: _jsx("button", {
        children: "I'm a <Button />!"
      })
    }), "\n", _jsx(_components.p, {
      children: "它渲染一个按钮！虽然这不是一个很好的按钮😕我们可以做得更好，让我们给它一些样式并激触发其中隐藏的美丽！"
    }), "\n", _jsx(_components.p, {
      children: "¥It renders a button! That's not a very nice button though 😕 we can do better than this,\nlet's give it a bit of styling and tickle out the hidden beauty within!"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-tsx",
        children: "const Button = styled.button`\n  background: transparent;\n  border-radius: 3px;\n  border: 2px solid #BF4F74;\n  color: #BF4F74;\n  margin: 0 1em;\n  padding: 0.25em 1em;\n`\n"
      })
    }), "\n", _jsx(AlignCenter, {
      children: _jsx(SecondButton, {
        children: "I'm a styled <Button />"
      })
    }), "\n", _jsx(_components.p, {
      children: "正如你所看到的，样式组件允许你在 JavaScript 中编写实际的 CSS。这意味着你可以使用你使用和喜爱的 CSS 的所有功能，包括（但到目前为止不限于）媒体查询、所有伪选择器、嵌套等。"
    }), "\n", _jsx(_components.p, {
      children: "¥As you can see, styled-components lets you write actual CSS in your JavaScript. This means you can use all the features of CSS you use and love, including (but by far not limited to) media queries, all pseudo-selectors, nesting, etc."
    }), "\n", _jsxs(_components.p, {
      children: ["最后一步是我们需要定义主按钮的外观。为此，我们还从 ", _jsx(_components.code, {
        children: "styled-components"
      }), " 导入 ", _jsx(_components.code, {
        children: "{ css }"
      }), " 并将函数插入到我们的模板字面量中，该函数传递了组件的 props："]
    }), "\n", _jsxs(_components.p, {
      children: ["¥The last step is that we need to define what a primary button looks like. To do that we also import ", _jsx(_components.code, {
        children: "{ css }"
      }), " from ", _jsx(_components.code, {
        children: "styled-components"
      }), " and interpolate a function into our template literal, which gets passed the props of our component:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-tsx",
        children: "import styled, { css } from 'styled-components'\n\nconst Button = styled.button<{ $primary?: boolean; }>`\n  background: transparent;\n  border-radius: 3px;\n  border: 2px solid #BF4F74;\n  color: '#BF4F74';\n  margin: 0 1em;\n  padding: 0.25em 1em;\n\n  ${props =>\n    props.$primary &&\n    css`\n      background: '#BF4F74';\n      color: white;\n    `};\n`\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["这里我们说的是，当设置 ", _jsx(_components.code, {
        children: "$primary"
      }), " 属性时，我们想要向组件添加更多 ", _jsx(_components.code, {
        children: "css"
      }), "，在本例中更改背景和颜色。"]
    }), "\n", _jsxs(_components.p, {
      children: ["¥Here we're saying that when the ", _jsx(_components.code, {
        children: "$primary"
      }), " property is set we want to add some more ", _jsx(_components.code, {
        children: "css"
      }), " to our component, in this case change the background and color."]
    }), "\n", _jsx(_components.p, {
      children: "就这样，我们完成了！看看我们完成的组件："
    }), "\n", _jsx(_components.p, {
      children: "¥That's all, we're done! Take a look at our finished component:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-react",
        children: "const Button = styled.button<{ $primary?: boolean; }>`\n  background: transparent;\n  border-radius: 3px;\n  border: 2px solid #BF4F74;\n  color: #BF4F74;\n  margin: 0.5em 1em;\n  padding: 0.25em 1em;\n\n  ${props => props.$primary && css`\n    background: #BF4F74;\n    color: white;\n  `}\n`;\n\nconst Container = styled.div`\n  text-align: center;\n`\n\nrender(\n  <Container>\n    <Button>Normal Button</Button>\n    <Button $primary>Primary Button</Button>\n  </Container>\n);\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "很好 😍 这也是一个实时更新的编辑器，所以尝试一下它，感受一下使用 styled-components 的感觉！准备好后，请深入阅读文档，了解 styled-components 可以为你做的所有很酷的事情："
    }), "\n", _jsx(_components.p, {
      children: "¥Nice 😍 That's a live updating editor too, so play around with it a bit to get a feel for what it's like to work with styled-components! Once you're ready, dive into the documentation to learn about all the cool things styled-components can do for you:"
    }), "\n", _jsx(NextPage, {
      title: "文档",
      href: "/docs"
    })]
  });
}
function MDXContent(props = {}) {
  return _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  }));
}
export default MDXContent;
